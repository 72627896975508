

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { Testimonial } from 'client-website-ts-library/types';
import { TestimonialFilter } from 'client-website-ts-library/filters';
import { API } from 'client-website-ts-library/services';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import Cycler from '../Cycler/Cycler.vue';
import CyclerItem from '../Cycler/CyclerItem.vue';
import TestimonialCard from './TestimonialCard.vue';
import TestimonialCards from './TestimonialCards.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
    TestimonialCard,
    TestimonialCards,
  },
})
export default class TestimonialCycler extends Vue {
  private testimonials: Testimonial[] = [];

  private breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 3,
      },
    },
  ];

  @Prop()
  private readonly filter!: TestimonialFilter;

  mounted() {
    this.updateTestimonials();
  }

  @Watch('filter', { deep: true })
  updateTestimonials() {
    API.Testimonials.Search(this.filter, true).then((testimonials) => {
      this.testimonials = testimonials;
      this.$emit('got_testimonial_count', testimonials.length);

      this.$emit('got_testimonials', testimonials);
    });
  }
}
